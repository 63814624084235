// @ts-ignore
import ComingSoon from 'react-coming-soon'
import logo from './logo.svg';


const App = () => {
  const twitterLink = <a style={{color:"#404040"}} href="//twitter.com/FurryHousing" rel="noopener noreferrer">@FurryHousing</a>;
  return (
    <ComingSoon title="Find your pack...soon" subtitle={twitterLink} bgColor="#FD5956" textColor="#404040" illustration="./logo.png" />
  );
};

export default App;
